import {Component}       from '@angular/core';
import {environment}     from "../environments/environment";
import {Router}          from "@angular/router";
import {PasswordService} from "./shared/services/password.service";

@Component({
             selector   : 'app-root',
             templateUrl: './app.component.html',
             styleUrl   : './app.component.scss'
           })
export class AppComponent {
  title = 'hraccess-registration-fe';

  constructor(private router: Router, public fpService: PasswordService) { }

  navigateToReg() {
    this.fpService.forgotFlag = true
  }

  navigateToForgotPassword() {
    this.router.navigate(['/forgotpwd']).then(r => console.log('navigated to /forgotpwd', r));
  }

  navigateToForRegister() {
    this.router.navigate(['/register']).then(r => console.log('navigated to /register', r));
  }

  navigateToFormerEmp() {
    window.open(environment.former_emp, "_self")
  }

  navigateToActiveEmp() {
    window.open(environment.active_emp, "_self")
  }

}
