<div *ngIf="loadSpinner" class="spinner">
  <mat-spinner></mat-spinner>
</div>
<ng-container *ngIf="!nextPage">
  <div class="change-pwd">
    <div class="changepwd-form">
      <form>
        <h2>Change Password</h2>
        <p class="hint-text">Please complete the fields below to change your password</p>
        <div *ngIf=!hideUserDetails>
          <div class="form-group row required">
            <label class="col-md-4" for="email">Personal Email:
            </label>
            <div class="col-md-6">
              <input (blur)="validationCheck('email');validateEmail($event)" (focus)="hidePasswordEye()"
                     [(ngModel)]="email" class="form-control"
                     id="email" name="email"
                     placeholder="Email" type="email">
              <span *ngIf="emailRequire" class="required-field">
                Email field is mandatory.
              </span>
              <span *ngIf="emailValidation" class="required-field">
                Please enter the valid Email.
              </span>
            </div>
          </div>
          <div class="form-group row required">
            <label class="col-md-4" for="mobile">Personal Mobile No:
            </label>
            <div class="col-md-6">
              <input (blur)="validationCheck('contact')" (focus)="hidePasswordEye()" [(ngModel)]="contact"
                     class="form-control"
                     id="mobile" maxlength="10"
                     name="contact" placeholder="Personal Mobile No"
                     type="tel">
              <span *ngIf="contactRequire" class="required-field">
                Personal Mobile No field is mandatory.
              </span>
              <span *ngIf="contactLength" class="required-field">
                Maximum & Minimum length is 10.
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4" for="altPhone">Alternate Phone No:
            </label>
            <div class="col-md-6">
              <input (blur)="validationCheck('altercontact')" (focus)="hidePasswordEye()" [(ngModel)]="altercontact"
                     class="form-control" id="altPhone"
                     maxlength="10" minlength="10"
                     name="altercontact" placeholder="Alternate Phone No"
                     type="tel">
              <span *ngIf="altercontactRequire" class="required-field">
                Personal Mobile No field is mandatory.
              </span>
              <span *ngIf="altercontactLength" class="required-field">
                Maximum & Minimum length is 10.
              </span>
            </div>
          </div>
        </div>

        <div class="form-group row required">
          <label class="col-md-4" for="newPwd">New Password:
            <img (mouseenter)="onPasswordHoverEntry()" (mouseleave)="onPasswordHoverExit()"
                 alt="help icon, question mark in circle" class="helpimageCss"
                 height="512"
                 matTooltipClass="my-custom-tooltip" ngSrc="assets/help.png" width="512" />

          </label>
          <div *ngIf="showPasswordCondition" class="password_modal">
            <section>
              All passwords last 90 days and must be at least 16 characters.Choose 3 of the four from
              following:
              <ul style="margin-bottom:10px;list-style:square;">
                <li> Upper case letters (A-Z)</li>
                <li> Lower case letters (a-z)</li>
                <li> Numbers (0-9)</li>
                <li> Special characters ! $ &commat; # % &</li>
              </ul>

              <p><u>Tips: Do not use:</u></p>
              <ul style="margin-bottom:10px;list-style:square;">
                <li> Same or similar password as before</li>
                <li> First, Middle or Last name</li>
                <li> UserID</li>
                <li> Sequential or repeated characters (ex. 123, abc, aaa)</li>
              </ul>
            </section>
          </div>
          <div class="col-md-6">

            <input (blur)="validationCheck('newPassword');validateNewPassword();" [(ngModel)]="newPassword"
                   [type]="hide ? 'password' : 'text'" class="form-control" id="newPwd" name="newPassword"
                   placeholder="Enter your password">
            <span *ngIf="npRequire" class="required-field">
              New Password field is mandatory.
            </span>
            <span *ngIf="passwordCondition" class="required-field">
              Please enter the valid password.
            </span>
          </div>
        </div>
        <div class="form-group row required">
          <label class="col-md-4" for="cnfrmPwd">Confirm Password:</label>
          <div class="col-md-6">
            <input (blur)="validatePassword('confirmPassword')" (focus)="hidePasswordEye()"
                   [(ngModel)]="confirmPassword" class="form-control" id="cnfrmPwd" name="confirmPassword"
                   placeholder="Confirm Password" type="password">
            <span *ngIf="cpRequire" class="required-field">
              Confirm Password is missing or Invalid, It should be same as new password.
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-4" for="secretQuestion">Secret Question:
            <img alt="secret question tooltip" class="helpimageCss" height="512" matTooltip="Choose a question whose answer is known only to you. If you forget your password, you'll be asked this
                question to verify your identity"
                 matTooltipClass="my-custom-tooltip" ngSrc="assets/help.png" width="512" />
          </label>
          <div *ngIf="showSecurityCondition" class="password_modal">
            <section>
              <p>Choose a question whose answer is known only to you. If you forget your password, you'll
                 be asked this
                 question
                 to
                 verify your identity</p>
            </section>
          </div>
          <div class="col-md-6">
            <mat-form-field id="secretQuestion" style="width: 100%;">
              <mat-label>Secret Question:</mat-label>
              <mat-select (blur)="validationCheck('securityQuestion')" [formControl]="securityQuestion">
                <mat-option *ngFor="let Security of securities" matTooltip="{{Security.value}}"
                            value="{{Security.value}}">
                  {{Security.viewValue}}
                </mat-option>
              </mat-select>
              <span *ngIf="questionRequire" class="required-field">
                Please Select Secret Question.
              </span>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group row required">
          <label class="col-md-4" for="secretAnswer">Secret Answer:
            <img alt="secret answer tooltip"
                 class="helpimageCss"
                 height="512"
                 matTooltip="Secret answer can contain following special characters:(space) + = _ ) ( * % $ # @ ! ` \ } ] { [ ; ? / . , -"
                 matTooltipClass="my-custom-tooltip" ngSrc="assets/help.png" width="512" />
          </label>
          <div class="col-md-6">
            <input (blur)="validationCheck('securityAnswer');validateAnswer()" (focus)="hidePasswordEye()"
                   [(ngModel)]="securityAnswer" class="form-control"
                   id="secretAnswer" name="securityAnswer" placeholder="Secret Answer"
                   type="text">
            <span *ngIf="answerRequire" class="required-field">
              Secret Answer field is Mandatory.
            </span>
            <span *ngIf="answerCondition" class="required-field">
              Please enter Secret Answer with valid special character
            </span>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-3" style="margin-left: 33%">
            <button (click)="changePassword()"
                    class="button-bottom btn btn-outline-success" type="submit">Submit
            </button>
          </div>
          <div class="col-md-3">
            <button (click)="cancel()" class="button-bottom btn btn-outline-danger"
                    type="submit">Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

</ng-container>

<ng-container *ngIf="nextPage">
  <ng-container *ngIf='employeeStatus == "A"'>
    <div class="confirmPage mat-elevation-z4">
      <h3 style="font-family: 'Roboto', sans-serif;">Success</h3>
      <p style="padding: 3%;font-size: 18px;font-family: Roboto, sans-serif;">
        Congratulations, you have successfully established your password.
        Your userid is <b>{{username}}</b> which you will use to login to the Former Employee site
        <span style="text-decoration: underline;">1-2 days after you are off payroll.</span>
        <br> <br> <br>
        <b>Next Steps:</b>
        Save this password that you established today in order to access the Former Employee site
        <span style="text-decoration: underline;">
                    1-2 days after you are off payroll. You will not have ability to login today
                </span>
        as you are still an Active employee.
        <br> <br> <br>
        Save or bookmark this site for easy access after you separate from the Company
        <a href="{{homeURL}}">{{homeURL}}</a>
        <br> <br> <br>
        <b>NOTE:</b>
        If you used the company LAN or VPN to login to the Former Employee site please close all browser
        windows to avoid conflicts with your current ATTUID.
      </p>
    </div>
  </ng-container>

  <ng-container *ngIf='employeeStatus == "F"'>
    <div class="confirmPage mat-elevation-z4">
      <h3 style="font-family: 'Roboto', sans-serif;">Success</h3>
      <p style="padding: 3%;font-size: 18px;font-family: Roboto, sans-serif;">
        Congratulations, you have successfully established your password. To access the Former Employee Site
        using your
        new password, click this link or copy and paste into an internet browser window:
        <a href="{{homeURL}}">{{homeURL}}</a>.
        Save or
        bookmark this site for easy access. If you are experiencing issues, try again a couple days after you
        are off
        payroll.
      </p>
    </div>
  </ng-container>

  <ng-container *ngIf='employeeStatus == "D"'>
    <div class="confirmPage mat-elevation-z4">
      <h3 style="font-family: 'Roboto', sans-serif;">Success</h3>
      <p style="padding: 3%;font-size: 18px;font-family: Roboto, sans-serif;">
        Congratulations, you have successfully established your password. To access the Former Employee Site
        using your
        new password, click this link or copy and paste into an internet browser window:
        <a href="{{homeURL}}">{{homeURL}}</a>.
        Save or
        bookmark this site for easy access.
      </p>
    </div>
  </ng-container>

  <div *ngIf="errorPage" class="confirmPage mat-elevation-z4">
    <h3 style="font-family: 'Roboto', sans-serif;">{{responseMSG}}</h3>
    <p style="padding: 3%;font-family: 'Roboto', sans-serif;">{{message}}</p>
    <ng-container *ngIf="invalidPassword">
      <button (click)="navigateToPage()">Ok</button>
    </ng-container>
    <div *ngIf="errorCounter >= 3" class="service_desk">
      <span>
        <p>
          Are you experiencing issues? Call HR OneStop at 888-722-1STP (888-722-1787) to speak directly to a HR
          OneStop Customer Care Manager. Speak “Former Employee Site”.
          <br>
          Hours of Operation: 8:00 am – 5:00 pm CST
        </p>
      </span>
    </div>
  </div>
</ng-container>
