<div *ngIf="loadSpinner" class="spinner">
  <mat-spinner></mat-spinner>
</div>
<!--<ng-container *ngIf="!nextPage">-->
<div class="temp-pwd">
  <div class="temppwd-form">
    <form>
      <h2>Reset Password</h2>
      <p class="hint-text">Enter the User Name and Temporary Password sent to you by text or email to reset your
                           password</p>
      <div class="form-group row required">
        <label class="col-md-4">User Name:</label>
        <div class="col-md-6">
          <input (blur)="validationCheck('username')" [(ngModel)]="username" class="form-control" name="username"
                 placeholder="User Name"
                 type="text">
          <span *ngIf="userRequire" style="color: #dc3545;">
              User Name field is mandatory.
            </span>
        </div>
      </div>
      <div class="form-group row required">
        <label class="col-md-4">Temporary Password:</label>
        <div class="col-md-6">
          <input (blur)="validationCheck('tempPassword')" [(ngModel)]="tempPassword" class="form-control"
                 name="tempPassword"
                 placeholder="Temporary Password" type="password">
          <span *ngIf="pwdRequire" style="color: #dc3545;">
              Temporary Password is mandatory.
            </span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4">Captcha Code:
          <img alt="" class="helpimageCss" height="512"
               matTooltip="Please enter the code exactly as it is displayed. If you would like a new code, click the
                                        refresh button." matTooltipClass="my-custom-tooltip" ngSrc="assets/help.png"
               width="512" />
        </label>
        <div class="col-md-3" id="captcha" style="background-color: bisque; margin-left: 2%"></div>
        <!-- <canvas class="col-md-3" id="canvasCapcha"></canvas> -->
        <div class="col-xs-3">
          <button (click)="createCaptcha()" class="btn btn-outline-info" type="button">
            <mat-icon>autorenew</mat-icon>
          </button>
        </div>
      </div>
      <div class="form-group row required">
        <label class="col-md-4">Enter Captcha:</label>
        <div class="col-md-6">
          <input (blur)="validateCaptcha('capchaValue')" [(ngModel)]="capchaValue" class="form-control"
                 name="capchaValue"
                 placeholder="Enter above code" type="text">
          <span *ngIf="capchaRequire" class="row" style="color: #dc3545;">
              Captcha code is missing or invalid, Please enter above text.
            </span>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-4" style="margin-left: 33%">
          <button (click)="resetPassword()"
                  class="button-bottom btn btn-outline-success" type="submit">Submit
          </button>
        </div>
        <div class="col-md-3">
          <button (click)="cancel()" class="button-bottom btn btn-outline-danger"
                  type="submit">Cancel
          </button>
        </div>
      </div>
      <div *ngIf="error" style="color: #dc3545">
        {{error}}
      </div>
      <div *ngIf="errorCounter >= 3" class="service_desk">
          <span>
            <p>
              Are you experiencing issues? Call HR OneStop at 888-722-1STP (888-722-1787) to speak directly to a HR
              OneStop Customer Care Manager. Speak “Former Employee Site”.
              <br>
              Hours of Operation: 8:00 am – 5:00 pm CST
            </p>
          </span>
      </div>
    </form>
  </div>
</div>
<!--</ng-container>-->
