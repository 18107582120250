import {Component, OnInit} from '@angular/core';
import {FormControl}       from "@angular/forms";
import {Router}            from "@angular/router";
import {PasswordService}   from "../shared/services/password.service";

@Component({
             selector   : 'app-forgot-password',
             templateUrl: './forgot-password.component.html',
             styleUrl   : './forgot-password.component.scss'
           })
export class ForgotPasswordComponent implements OnInit {

  public username: any            = '';
  public nextPage: Boolean        = false;
  public usernameRequire: Boolean = false;
  public securityAnswer: any      = '';
  public answerRequire: Boolean   = false;
  public securityQuestion         = new FormControl();
  public questionRequire: Boolean = false;
  public message: any;
  public responseMSG: string      = '';
  public securities: Security[]   = [
    {viewValue: 'Model name of my first car?', value: 'Model name of my first car?'},
    {viewValue: 'Favorite pet name?', value: 'Favorite pet name?'},
    {viewValue: 'Favorite movie?', value: 'Favorite movie?'},
    {viewValue: 'Favorite musical group?', value: 'Favorite musical group?'},
    {viewValue: 'Favorite song?', value: 'Favorite song?'},
    {viewValue: 'Favorite restaurant?', value: 'Favorite restaurant?'},
    {viewValue: 'Favorite drink?', value: 'Favorite drink?'},
    {viewValue: 'Name of the best man at your wedding?', value: 'Name of the best man at your wedding?'},
    {viewValue: 'Name of the maid of honor at your wedding?', value: 'Name of the maid of honor at your wedding?'},
    {viewValue: 'Name of your first boyfriend/girlfriend?', value: 'Name of your first boyfriend/girlfriend?'},
    {
      viewValue: 'City of the first live concert that you attended?',
      value    : 'City of the first live concert that you attended?'
    },
    {viewValue: 'AT&T HR Codeword?', value: 'AT&T HR Codeword?'}
  ];
  errorCounter: any               = 0;
  loadSpinner: boolean            = false;

  constructor(private router: Router, private fpService: PasswordService) { }

  ngOnInit() {
  }

  validationCheck(event: any) {
    if (event === 'username') {
      this.username === '' ? this.usernameRequire = true : this.usernameRequire = false;
    } else if (event === 'securityAnswer') {
      this.securityAnswer === '' ? this.answerRequire = true : this.answerRequire = false;
    } else if (event === 'securityQuestion') {
      this.securityQuestion.value == null ? this.questionRequire = true : this.questionRequire = false;
    }
  }

  forgotPassword() {
    this.username === '' ? this.usernameRequire = true : this.usernameRequire = false;
    this.securityAnswer === '' ? this.answerRequire = true : this.answerRequire = false;
    this.securityQuestion.value == null ? this.questionRequire = true : this.questionRequire = false;
    if (!this.usernameRequire && !this.answerRequire && !this.questionRequire) {
      const registerObj = {
        'userId'          : this.username,
        'securityQuestion': this.securityQuestion.value,
        'securityAnswer'  : this.securityAnswer
      };
      this.loadSpinner  = true;
      this.fpService.forgotPassword(registerObj).subscribe(
        {
          next : (data: any) => {
            this.nextPage = true;
            if (data.returnCode === '0') {
              this.loadSpinner  = false;
              this.errorCounter = 0;
              this.responseMSG  = 'Success';
              this.message
                                = 'We have sent a password and reset link to the email address of the account that you specified. Please check your email and follow the instructions.';
            } else if (data.returnCode === '-1') {
              this.errorCounter = this.errorCounter + 1;
              this.responseMSG  = 'Error';
              this.message      = data.message;
              this.loadSpinner  = false;
            } else {
              this.responseMSG = 'Error';
              this.message     = data.message;
              this.loadSpinner = false;
            }
          },
          error: (error: any) => {
            this.loadSpinner = false;
            console.log(error);
          }
        }
      );
    }
  }

  navigateToPage() {
    if (this.responseMSG === 'Success') {
      this.router.navigate(['/']).then(r => console.log('navigated to /', r));
    } else {
      this.nextPage = false;

    }
  }

  cancel() {
    this.router.navigate(['/']).then(r => console.log('navigated to /', r));
  }

  navigateToReg() {
    this.fpService.forgotFlag = true;
  }
}

export interface Security {
  viewValue: string;
  value: string;
}
