import {Injectable}              from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment}             from "../../../environments/environment";

@Injectable({
              providedIn: 'root'
            })
export class PasswordService {

  public attuid: string      = '';
  public forgotFlag: boolean = false;

  constructor(private http: HttpClient) { }

  public changePassword(data: any) {
    return this.http.post<any>(`${environment.API_URL}/changePassword?d=` + new Date().getTime(), data,
                               this.getRequestOptions());
  }

  public resetPassword(data: any) {
    return this.http.post<any>(`${environment.API_URL}/resetPassword?d=` + new Date().getTime(), data,
                               this.getRequestOptions());
  }

  public forgotPassword(data: any) {
    return this.http.post<any>(`${environment.API_URL}/forgotPassword?d=` + new Date().getTime(), data,
                               this.getRequestOptions());
  }

  public resetPasswordForActive(data: any) {
    return this.http.post<any>(`${environment.API_URL}/resetPasswordForExitingEmployee?d=` + new Date().getTime(),
                               data,
                               this.getRequestOptions());
  }

  public createExitEmplSecurityProfile(data: any) {
    return this.http.post<any>(`${environment.API_URL}createExitEmployeeSecurityProfile?d=` + new Date().getTime(),
                               data,
                               this.getRequestOptions());
  }

  // public getTheDetails(attuid: any) {
  //   return this.http.get<any>(`${environment.API_URL}/getFormerEmpInfoWithCsp/` + attuid + `?d=` + new
  // Date().getTime(), this.getRequestOptions()); }

  public getTheDetails(data: any) {
    return this.http.post<any>(`${environment.API_URL}/getFormerEmployeeInfoWithCsp?d=` + new Date().getTime(), data,
                               this.getRequestOptions());
  }

  // public getEmployeeStatus(attuid: any) {
  //   return this.http.get<any>(`${environment.API_URL}/getEmployeeStatus/` + attuid + `?d=` + new Date().getTime(),
  //     this.getRequestOptions());
  // }

  public getEmployeeStatus(data: any) {
    return this.http.post<any>(`${environment.API_URL}/getEmployeeStatus?d=` + new Date().getTime(), data,
                               this.getRequestOptions());
  }

  // public handleError<T>(result?: T) {
  //   return (error: Response | any): Observable<T> => {
  //     let errMsg: string;
  //     if (error instanceof Response) {
  //       const body = error.json() || '';
  //       const err  = body || JSON.stringify(body);
  //       errMsg     = `${error.status} - ${error.statusText || ''} ${err}`;
  //     } else {
  //       errMsg = error.message ? error.message : error.toString();
  //     }
  //     console.error(errMsg);
  //     return of(result as T);
  //   };
  // }

  private getRequestOptions() {
    const header = new HttpHeaders({
                                     'Content-Type': 'application/json'
                                   });
    return {
      headers        : header,
      withCredentials: true
    };
  }
}
