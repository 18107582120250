<div *ngIf="loggedin">
  <div *ngIf="loadSpinner" class="spinner">
    <mat-spinner></mat-spinner>
  </div>
  <div *ngIf="notauthorized" class="register">
    <form>
      <div class="confirmPage mat-elevation-z4">
        <h2>Error</h2>
        <p class="hint-text">You are not authorized</p>
      </div>
    </form>
  </div>
  <div *ngIf="!confirmPage && authorized" class="register">
    <form>
      <div class="signup-form">
        <h2>Former Employee Profile Administration Tool</h2>
        <p class="hint-text">Please type in the date of birth and SSN of your client to lookup profile details!
        </p>
        <div class="form-group row required">
          <label class="col-md-3">Date of Birth:
                                  <!-- <mat-icon matTooltip="Your date of birth is required to validate and authenticate your registration for the
                                          Former Employee Site." aria-hidden="false" aria-label="Example home icon">
                                      help
                                  </mat-icon> -->
            <img alt="" class="helpimageCss" height="512"
                 matTooltip="Your date of birth is required to validate and authenticate your registration for the
                                Former Employee Site." matTooltipClass="my-custom-tooltip" ngSrc="assets/help.png" width="512" />
          </label>
          <div *ngIf="showDOBCondition" class="help_modal">
            <section>
              <p>Your date of birth is required to validate and authenticate your registration for the
                 Former Employee Site.</p>
            </section>
          </div>
          <div class="col-md-6">
            <mat-form-field class="form-control" style="height: 58px;">
              <input (blur)="validationCheck('dob')" (click)="birthDate.open()" (focus)="birthDate.open()" [(ngModel)]="dob"
                     [matDatepicker]="birthDate" [max]="maxDate" matInput
                     name="dob" placeholder="Date of birth MM/DD/YYYY" type="password">
              <mat-datepicker-toggle [for]="birthDate" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #birthDate></mat-datepicker>
            </mat-form-field>
            <span *ngIf="dobRequire" style="color: #dc3545;">
                            Date of Birth field is mandatory.
                        </span>
          </div>
        </div>
        <div class="form-group row required">
          <label class="col-md-3">SSN:
                                  <!-- <mat-icon matTooltip="Your SSN is required to validate and authenticate your registration for the Former
                                          Employee Site" aria-hidden="false" aria-label="Example home icon">help
                                  </mat-icon> -->
            <img alt="" class="helpimageCss" height="512"
                 matTooltip="Your SSN is required to validate and authenticate your registration for the Former
                                Employee Site" matTooltipClass="my-custom-tooltip" ngSrc="assets/help.png" width="512" />

          </label>
          <div *ngIf="showSSNCondition" class="help_modal">
            <section>
              <p>Your SSN is required to validate and authenticate your registration for the Former
                 Employee Site</p>
            </section>
          </div>
          <div class="col-md-7">
            <input (blur)="validationCheck('ssn')" (keyup)="textCounter('ssn1')" [(ngModel)]="firstssn" class="col-md-3"
                   maxlength="3" name="firstssn" type="password">

            <span class="inputConnector">-</span>
            <input (blur)="validationCheck('ssn')" (keyup)="textCounter('ssn2')" [(ngModel)]="midssn" class="col-md-2"
                   maxlength="2" name="midssn" type="password">

            <span class="inputConnector">-</span>
            <input (blur)="validationCheck('ssn')" [(ngModel)]="lastssn" class="col-md-3" maxlength="4" name="lastssn"
                   type="password">
            <span *ngIf="ssnRequire || firstssnlength || midssnlength || lastssnlength" class="row"
                  style="color: #dc3545;">
                            SSN is required
                        </span>
          </div>

        </div>
        <div class="form-group row">
          <div class="col-md-3" style="margin-left: 25%">
            <button (click)="getEmpData()"
                    class="button-bottom btn btn-outline-success" type="submit">Submit
            </button>
          </div>
          <div class="col-md-3">
            <button (click)="clearData()" class="button-bottom btn btn-outline-danger"
                    type="submit">Clear
            </button>
          </div>
        </div>
      </div>
    </form>

  </div>
  <div *ngIf="confirmPage && authorized" class="register">
    <div *ngIf=!error class="signup-form">
      <h2 style="font-family: 'Roboto', sans-serif;">Success</h2>
      <div class="form-group row">
        <label class="col-md-3">User ID:</label>
        <div class="col-md-6">
          {{formerEmpData.userId}}
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3">First Name:</label>
        <div class="col-md-6">
          {{formerEmpData.firstName}}
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3">Last Name:</label>
        <div class="col-md-6">
          {{formerEmpData.lastName}}
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3">Email:</label>
        <div class="col-md-6">
          {{formerEmpData.emailAddress}}
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3">Personal Mobile No:</label>
        <div class="col-md-6">
          {{cell}}
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3">Alternate Phone No:</label>
        <div class="col-md-6">
          {{homePhone}}
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-3" style="margin-left: 25%">
          <button (click)="navigateToPage()" class="button-bottom btn btn-outline-success"
                  type="submit">Ok
          </button>
        </div>

      </div>
    </div>
    <div *ngIf=error class="confirmPage mat-elevation-z4">
      <h3 style="font-family: 'Roboto', sans-serif;">Error</h3>
      <p style="font-family: 'Roboto', sans-serif;">
        {{message}}
      </p>
      <div class="form-group row">
        <div class="col-md-3" style="margin-left:37%">
          <button (click)="navigateToPage()" class="button-bottom btn btn-outline-success"
                  type="submit">Ok
          </button>
        </div>

      </div>
    </div>
  </div>
</div>
