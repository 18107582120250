<header class="mt-5">
  <div class="container text-center">
    <h1 class="page-title mb-5">AT&amp;T HR Access</h1>
    <p class="lead pb-4">
      AT&amp;T HR Access is the place for active and former employees and their dependents to access benefits and
      company information anytime anywhere.
    </p>
  </div>
</header>
<section class="container section-wrapper mx-auto overflow-y-auto"
         id="services">
  <div class="row text-center">
    <div class="col-md-4 mb-4 d-flex justify-content-center">
      <div class="card h-100">
        <div class="mt-3 d-flex justify-content-center">
          <img alt="Active Employee" class="iconActiveUser" height="116"
               ngSrc="assets/blue_icon_person.png"
               width="116">
        </div>
        <div class="card-body">
          <h2 class="card-title">Active Employee <br>Including Employees on
                                 Short Term Disability, Leave
                                 of
                                 Absence or
                                 Suspension</h2>
          <p class="card-text">Please use your ATTUID and Global
                               Logon to log in from any location
                               (work or non-work)</p>
        </div>
        <div class="card-footer py-4">
          <button (click)="navigateToActiveEmp()" class="btn btn-outline-primary sm-4" type="button">
            Login
          </button>
        </div>
      </div>
    </div>

    <div class="col-md-4 mb-4 d-flex justify-content-center">
      <div class="card h-100">
        <div class="mt-3 d-flex justify-content-center">
          <img alt="Former Employee" class="iconActiveUser" height="116" ngSrc="assets/light_green_icon_person.png"
               width="116">
        </div>
        <div class="card-body">
          <h2 class="card-title">Retiree, Former Employee,
                                 or Dependent</h2>
          <p class="card-text"><a [routerLink]="['/newUser']">New User?</a> Please register for
                                                                            your Global Log-on</p>
        </div>
        <div class="card-footer py-4">
          <div class="d-flex justify-content-center">
            <button (click)="navigateToFormerEmp()" class="btn btn-outline-primary sm-4 mx-2" type="button">
              Login
            </button>
            <button (click)="navigateToForRegister()" class="btn btn-outline-primary sm-4 mx-2"
                    type="button">
              Register
            </button>
          </div>

          <div class="classLinks d-flex justify-content-center py-3">
            <label class="mx-2" for="forgot" id="forgot">Forgot</label>
            <a [routerLinkActive]="'active'" [routerLink]="['/forgotpwd']" class="mx-2">Password&raquo;</a>
            <a (click)="navigateToReg()" [routerLinkActive]="'active'" [routerLink]="['/forgotuser']" class="mx-2"
            >User Name&raquo;</a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4 mb-4 d-flex justify-content-center">
      <div class="card h-100">
        <div class="mt-3 d-flex justify-content-center">
          <img alt="Active Employee" class="iconActiveUser" height="116" ngSrc="assets/blue_icon_person.png"
               width="116">
        </div>
        <div class="card-body">
          <h2 class="card-title">Non-Management Internal CareerPath</h2>
          <p class="card-text">
            Active Wireline Non-Management employees can search and apply for jobs by clicking Login,
            even from a non-work location.
            <br>
            Former Eligible Wireline Non-Management employees must register first. Once registered, the
            information provided during the registration process should be used to Login
          </p>
        </div>

        <div class="card-footer py-4">
          <div class="d-flex justify-content-center">
            <a class="btn btn-outline-primary sm-4 mx-2" href="https://www.e-access.att.com/nmic/nmic70/ESSLoginServlet"
            >Login</a>
            <button (click)="navigateToForRegister()" class="btn btn-outline-primary sm-4 mx-2" type="button">
              Register
            </button>
          </div>

          <div class="classLinks d-flex justify-content-center py-3">
            <label class="mx-2" for="forgotPwd" id="forgotPwd">Forgot</label>
            <a [routerLinkActive]="'active'" [routerLink]="['/forgotpwd']" class="mx-2">Password&raquo;</a>
            <a (click)="navigateToReg()" [routerLinkActive]="'active'" [routerLink]="['/register']" class="mx-2">User
                                                                                                                 Name&raquo;</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="container-fluid text-center py-4 mt-4">
  <p class="lead pb-3">
    Looking for the Access from AT&T low-cost wireline home internet program?
    <a class="link" href="https://www.att.com/shop/internet/access/index.html#/">Click here</a>
    to be redirected.
  </p>
</section>
