import {Component, Inject}             from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogData}                    from "../../shared/interface/dialog-data";

@Component({
             selector   : 'app-faq',
             templateUrl: './faq.component.html',
             styleUrl   : './faq.component.scss'
           })
export class FaqComponent {

  constructor(
    public helpDialogRef: MatDialogRef<FaqComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    console.log('HelpAndFaqDialog Component');
    console.log(data);
  }

  close(): void {
    this.helpDialogRef.close();
  }
}
