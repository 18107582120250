<div class="nav-wrapper">
  <nav class="navbar navbar-expand-lg navbar-light bg-light mb-2" style="background-color: black !important;">
    <div>
      <img [ngSrc]="'assets/ATT_Globe.png'" alt="" class="mx-2" height="50" width="50" />
    </div>
  </nav>
</div>
<!--<div class='downTime'>
	Downtime Notice : HR Access Active and Former Employee Sites will be down for maintenance starting Friday, 12/01 5 PM CT till Monday, 12/04 5 AM CT. During this period, all the functions in HR Access will remain inaccessible.
</div> -->
<router-outlet />
<app-footer />
