import {NgModule}                   from '@angular/core';
import {RouterModule, Routes}       from '@angular/router';
import {HelpDeskComponent}          from "./help-desk/help-desk.component";
import {DashboardComponent}         from "./dashboard/dashboard.component";
import {RegistrationComponent}      from "./registration/registration.component";
import {ForgotPasswordComponent}    from "./forgot-password/forgot-password.component";
import {ChangePasswordComponent}    from "./change-password/change-password.component";
import {TempPasswordComponent}      from "./temp-password/temp-password.component";
import {UpdateInformationComponent} from "./update-information/update-information.component";
import {NewUserComponent}           from "./new-user/new-user.component";

const routes: Routes = [
  {
    path: 'dashboard', component: DashboardComponent
  },
  {
    path: 'register', component: RegistrationComponent
  },
  {
    path: 'forgotpwd', component: ForgotPasswordComponent
  },
  {
    path: 'changepwd/:activeFlag', component: ChangePasswordComponent
  },
  {
    path: 'resetpwd/:activeFlag', component: TempPasswordComponent
  },
  {
    path: 'updateInfo', component: UpdateInformationComponent
  },
  {
    path: 'helpdesk', component: HelpDeskComponent
  },
  {
    path: 'newUser', component: NewUserComponent
  },
  {
    path: '**', redirectTo: 'dashboard', pathMatch: 'full'
  }
];

@NgModule({
            imports: [RouterModule.forRoot(routes)],
            exports: [RouterModule]
          })
export class AppRoutingModule {}
