import {Component, OnInit} from '@angular/core';
import {DatePipe}          from "@angular/common";
import {AuthService}       from "../shared/services/auth.service";
import {environment}       from "../../environments/environment";
import {HelpdeskService}   from "../shared/services/helpdesk.service";

@Component({
             selector   : 'app-help-desk',
             templateUrl: './help-desk.component.html',
             styleUrl   : './help-desk.component.scss'
           })
export class HelpDeskComponent implements OnInit {

  cell: any;
  formerEmpData: any;
  public homePhone: string         = '';
  public confirmPage: Boolean      = false;
  public loadSpinner: Boolean      = false
  public maxDate                   = new Date();
  public dob: any                  = '';
  public firstssn: any             = '';
  public midssn: any               = '';
  public lastssn: any              = '';
  public showDOBCondition: Boolean = false;
  public showSSNCondition: Boolean = false;
  public dobRequire: Boolean       = false;
  public firstssnRequire: Boolean  = false;
  public midssnRequire: Boolean    = false;
  public lastssnRequire: Boolean   = false;
  message: any;
  error: boolean                   = false;
  authorized: boolean              = false;
  loggedin: boolean                = false;
  notauthorized: boolean           = false;
  ssnRequire: boolean              = false;
  firstssnlength: boolean          = false;
  midssnlength: boolean            = false;
  lastssnlength: boolean           = false;

  constructor(public datepipe: DatePipe, private helpDeskService: HelpdeskService, private authservice: AuthService) { }

  ngOnInit() {
    this.loggedin = false
    this.getUserInfo()
    // this.authorized = true;
    // this.loggedin = true
    // this.loadSpinner = false;
  }

  textCounter(event: any) {
    if (event == 'ssn1' && this.firstssn.length == 3) {
      document.getElementsByName('midssn')[0].focus()
    } else if (event == 'ssn2' && this.midssn.length == 2) {
      document.getElementsByName('lastssn')[0].focus()
    }
  }

  validationCheck(event: any) {
    if (event === 'dob') {
      this.dob === '' ? this.dobRequire = true : this.dobRequire = false;
    } else if (event === 'ssn') {
      this.firstssn === '' ? this.ssnRequire = true : this.ssnRequire = false;
      this.midssn === '' ? this.ssnRequire = true : this.ssnRequire = false;
      this.lastssn === '' ? this.ssnRequire = true : this.ssnRequire = false;
      this.firstssn.length < 3 ? this.firstssnlength = true : this.firstssnlength = false;
      this.midssn.length < 2 ? this.midssnlength = true : this.midssnlength = false;
      this.lastssn.length < 4 ? this.lastssnlength = true : this.lastssnlength = false;
    }
  }

  getEmpData() {
    this.dob === '' ? this.dobRequire = true : this.dobRequire = false;
    this.firstssn === '' ? this.firstssnRequire = true : this.firstssnRequire = false;
    this.midssn === '' ? this.midssnRequire = true : this.midssnRequire = false;
    this.lastssn === '' ? this.lastssnRequire = true : this.lastssnRequire = false;
    if (!this.dobRequire && !this.firstssnRequire && !this.midssnRequire && !this.lastssnRequire) {
      let data         = {
        'dob': this.datepipe.transform(this.dob, 'MM-dd-yyyy'),
        'ssn': this.firstssn + this.midssn + this.lastssn,
      }
      this.loadSpinner = true
      this.message     = ''
      this.error       = false;
      this.helpDeskService.getEmpData(data).subscribe(
        {
          next : (data: any) => {
            if (data) {
              this.loadSpinner = false
              this.confirmPage = true;
              if (data.returnCode == 0) {
                this.formerEmpData = data.cspUserData;
                this.homePhone     = data.cspUserData.homePhone == 0 ? '' : data.cspUserData.homePhone
                this.cell          = data.cspUserData.cell == 0 ? '' : data.cspUserData.cell;
              } else {
                this.error   = true;
                this.message = data.message
              }
            }
          },
          error: (error: any) => {
            console.log(error);
          }
        }
      );
    }
  }

  navigateToPage() {
    this.confirmPage = false
    this.clearData();
  }

  getUserInfo() {
    this.loadSpinner = true
    sessionStorage.setItem('helpdesk', ' true');

    this.authservice.getUserInfo().subscribe(
      {
        next    : (data: any) => {

          if (data == null) {
            this.loadSpinner = false
            this.loggedin    = true
            location.replace(environment.Global_Logon);
            console.log('Login data received: ', data);
          } else {
            if (data.HR_ACCESS_HELPDESK_ADMIN == '420') {
              this.authorized  = true;
              this.loggedin    = true
              this.loadSpinner = false;

            } else {
              this.notauthorized = true;
              this.loggedin      = true
              this.loadSpinner   = false
            }
          }
        }, error: (error: any) => {
          this.loadSpinner = false
          console.log('Error received as: ', JSON.stringify(error));
        }
      }
    );
  }

  clearData() {
    this.dob      = '';
    this.firstssn = '';
    this.midssn   = '';
    this.lastssn  = '';
  }

}
