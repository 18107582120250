import {Injectable}                 from '@angular/core';
import {HttpClient, HttpHeaders}    from "@angular/common/http";
import {catchError, Observable, of} from "rxjs";
import {environment}                from "../../../environments/environment";

@Injectable({
              providedIn: 'root'
            })
export class HelpdeskService {

  constructor(private http: HttpClient) { }

  public getEmpData(data: any) {
    return this.http.post<any>(`${environment.API_URL}/userDetails`, data,
                               this.getRequestOptions()).pipe(catchError(this.handleError<any>()));
  }

  public handleError<T>(result?: T) {
    return (error: Response | any): Observable<T> => {
      let errMsg: string;
      if (error instanceof Response) {
        const body = error.json() || '';
        const err  = body || JSON.stringify(body);
        errMsg     = `${error.status} - ${error.statusText || ''} ${err}`;
      } else {
        errMsg = error.message ? error.message : error.toString();
      }
      console.error("Error occurred", errMsg);
      return of(result as T);
    };
  }

  private getRequestOptions() {
    const header = new HttpHeaders({
                                     'Content-Type': 'application/json'
                                   });
    return {
      headers        : header,
      withCredentials: true
    };
  }
}
