import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule}                    from '@angular/platform-browser';

import {AppRoutingModule}                                                                 from './app-routing.module';
import {AppComponent}                                                                     from './app.component';
import {
  provideAnimationsAsync
}                                                                                         from '@angular/platform-browser/animations/async';
import {
  ChangePasswordComponent
}                                                                                         from './change-password/change-password.component';
import {
  DashboardComponent
}                                                                                         from './dashboard/dashboard.component';
import {
  FooterComponent
}                                                                                         from './footer/footer.component';
import {
  ForgotPasswordComponent
}                                                                                         from './forgot-password/forgot-password.component';
import {
  HelpDeskComponent
}                                                                                         from './help-desk/help-desk.component';
import {
  RegistrationComponent
}                                                                                         from './registration/registration.component';
import {
  TempPasswordComponent
}                                                                                         from './temp-password/temp-password.component';
import {
  UpdateInformationComponent
}                                                                                         from './update-information/update-information.component';
import {
  MatDividerModule
}                                                                                         from "@angular/material/divider";
import {CommonModule, DatePipe, HashLocationStrategy, LocationStrategy, NgOptimizedImage} from "@angular/common";
import {
  BrowserAnimationsModule
}                                                                                         from "@angular/platform-browser/animations";
import {HTTP_INTERCEPTORS, HttpClientModule}                                              from "@angular/common/http";
import {
  MatProgressSpinnerModule
}                                                                                         from "@angular/material/progress-spinner";
import {FormsModule, ReactiveFormsModule}                                                 from "@angular/forms";
import {
  MatInputModule
}                                                                                         from "@angular/material/input";
import {
  MatFormFieldModule
}                                                                                         from "@angular/material/form-field";
import {
  MatDatepickerModule
}                                                                                         from "@angular/material/datepicker";
import {MatNativeDateModule}                                                              from "@angular/material/core";
import {MatIconModule}                                                                    from "@angular/material/icon";
import {
  MatSelectModule
}                                                                                         from "@angular/material/select";
import {
  MatTooltipModule
}                                                                                         from "@angular/material/tooltip";
import {
  MatAutocompleteModule
}                                                                                         from "@angular/material/autocomplete";
import {
  RegisterService
}                                                                                         from "./shared/services/register.service";
import {
  PasswordService
}                                                                                         from "./shared/services/password.service";
import {
  AuthService
}                                                                                         from "./shared/services/auth.service";
import {
  UpdateContactService
}                                                                                         from "./shared/services/update-contact.service";
import {
  HelpdeskService
}                                                                                         from "./shared/services/helpdesk.service";
import {
  NewUserComponent
}                                                                                         from './new-user/new-user.component';
import {
  MessageComponent
}                                                                                         from './_dialogs/message/message.component';
import {
  FaqComponent
}                                                                                         from './_dialogs/faq/faq.component';
import {
  MatDialogModule
}                                                                                         from "@angular/material/dialog";
import {
  MatIconButton
}                                                                                         from "@angular/material/button";
import {
  AppInterceptor
}                                                                                         from "./interceptors/app.interceptor";

@NgModule({
            declarations: [
              AppComponent,
              ChangePasswordComponent,
              DashboardComponent,
              FooterComponent,
              ForgotPasswordComponent,
              HelpDeskComponent,
              RegistrationComponent,
              TempPasswordComponent,
              UpdateInformationComponent,
              NewUserComponent,
              MessageComponent,
              FaqComponent
            ],
            imports     : [
              CommonModule,
              BrowserModule,
              BrowserAnimationsModule,
              HttpClientModule,
              AppRoutingModule,
              MatProgressSpinnerModule,
              FormsModule,
              ReactiveFormsModule,
              MatInputModule,
              MatFormFieldModule,
              MatDatepickerModule,
              MatIconModule,
              MatNativeDateModule,
              MatSelectModule, MatTooltipModule,
              MatAutocompleteModule,
              MatDividerModule,
              MatDialogModule,
              NgOptimizedImage,
              MatIconButton
            ],
            schemas     : [
              CUSTOM_ELEMENTS_SCHEMA
            ],
            providers   : [
              {
                provide : HTTP_INTERCEPTORS,
                useClass: AppInterceptor, multi: true
              },
              provideAnimationsAsync(),
              MatDatepickerModule,
              MatNativeDateModule,
              RegisterService,
              PasswordService,
              AuthService,
              UpdateContactService,
              DatePipe,
              HelpdeskService,
              {provide: LocationStrategy, useClass: HashLocationStrategy,},
            ],
            bootstrap   : [AppComponent]
          })
export class AppModule {}
