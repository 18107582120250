<div class="w-100">
  <h1 class="w-100 text-center" mat-dialog-title>{{data.header}}</h1>
  <div mat-dialog-content>
    <p [innerHtml]="data.message"></p>
  </div>
  <div class="w-100 text-center" mat-dialog-actions>
    <button (click)="ok()" class="button-bottom btn btn-outline-success mx-auto"
            type="submit">Ok
    </button>
  </div>
</div>
