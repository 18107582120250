import {Component, OnInit}       from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {Router}                  from "@angular/router";
import {RegisterService}         from "../shared/services/register.service";
import {PasswordService}         from "../shared/services/password.service";

@Component({
             selector   : 'app-registration',
             templateUrl: './registration.component.html',
             styleUrl   : './registration.component.scss'
           })
export class RegistrationComponent implements OnInit {

  public attuidRequire: boolean      = false;
  public fnRequire: boolean          = false;
  public lnRequire: boolean          = false;
  public emailRequire: boolean       = false;
  public contactRequire: boolean     = false;
  public dobRequire: boolean         = false;
  public ssnRequire: boolean         = false;
  public captchaRequire: boolean     = false;
  public showSSNCondition: boolean   = false;
  public readonlyAttuid: boolean     = false;
  public readonlyName: boolean       = false;
  public attuid: string              = '';
  public firstname: string           = '';
  public lastname: string            = '';
  public email: string               = '';
  public contact: string             = '';
  public countryCode: string         = '+1';
  public altercontact: string        = '';
  public firstssn: string            = '';
  public midssn: string              = '';
  public lastssn: string             = '';
  public captchaValue: string        = '';
  public userId: string              = '';
  public pageTitle: string           = '';
  public emailToolTip: string        = '';
  public captchaCode: any            = '';
  public errorCounter: number        = 0;
  public altercontactLength: boolean = false;
  public confirmPage: boolean        = false;
  public contactLength: boolean      = false;
  public emailValidation: boolean    = false;
  public existingFlag: boolean       = false;
  public existingUser: boolean       = false;
  public firstSsnLength: boolean     = false;
  public loadSpinner: boolean        = false;
  public midSsnLength: boolean       = false;
  public lastSsnLength: boolean      = false;
  public registrationFlow: boolean   = false;
  public dobDD: number | null        = null;
  public dobMM: number | null        = null;
  public dobYYYY: number | null      = null;
  public responseMSG: string | null  = null;
  public existingPasswordChange: any;
  public message: any;
  twoDigitsMM                        = new FormControl('', [
    Validators.minLength(1),
    Validators.maxLength(2),
    Validators.pattern('^[0-9]*$') // ensures only numbers are entered
  ]);
  twoDigitsDD                        = new FormControl('', [
    Validators.minLength(1),
    Validators.maxLength(2),
    Validators.pattern('^[0-9]*$') // ensures only numbers are entered
  ]);
  fourDigitsYYYY                     = new FormControl('', [
    Validators.minLength(4),
    Validators.maxLength(4),
    Validators.pattern('^[0-9]*$') // ensures only numbers are entered
  ]);

  constructor(private router: Router, private registerService: RegisterService,
              public passwordService: PasswordService) {}

  ngOnInit() {

    this.confirmPage = false;
    setTimeout(() => {
      console.log('hide');
      this.createCaptcha();
    }, 2000);

    if (this.passwordService.forgotFlag) {
      this.existingFlag = true;
    }
    setTimeout(() => {
      console.log('register', this.router.url.includes('register'), 'forgotuser',
                  this.router.url.includes('forgotuser'));
      if (this.router.url.includes('register')) {
        this.pageTitle        = 'Register';
        this.registrationFlow = true;
        this.emailToolTip     = 'Please enter an email we may use to send registration and password reset'
                                + ' instructions.';
      } else if (this.router.url.includes('forgotuser')) {
        this.pageTitle                  = 'Forgot User';
        this.passwordService.forgotFlag = true;
        this.registrationFlow           = false;
        this.emailToolTip               = 'If you would like to change your email in your current profile '
                                          + 'please type it in here';
      }
    }, 10)
  }

  textCounter(event: string) {
    if (event === 'attuid') {
      console.log({event: this.attuid});
      this.readonlyName = this.attuid.length > 0;
    } else if (event === 'firstname' || event === 'lastname') {
      console.log({event: this.firstname.length + ' ' + this.lastname.length});
      this.readonlyAttuid = this.firstname.length > 0 || this.lastname.length > 0;
    } else if (event === 'ssn1' && this.firstssn.length === 3) {
      document.getElementsByName('midssn')[0].focus();
    } else if (event === 'ssn2' && this.midssn.length === 2) {
      document.getElementsByName('lastssn')[0].focus();
    }
  }

  validationCheck(event: any) {
    this.requireCheck();
    this.attuidCheck(event);
    this.firstnameCheck(event);
    this.lastnameCheck(event);
    this.emailCheck(event);
    this.contactCheck(event);
    this.altercontactCheck(event);
    this.birthdayCheck(event);
    this.ssnCheck(event);
  }

  validateDate() {
    console.log(this.twoDigitsMM.value, this.twoDigitsDD.value, this.fourDigitsYYYY.value);
    const mm         = ('' + this.twoDigitsMM.value).length < 2 ? '0' + this.twoDigitsMM.value
                                                                : this.twoDigitsMM.value;
    const dd         = ('' + this.twoDigitsDD.value).length < 2 ? '0' + this.twoDigitsDD.value
                                                                : this.twoDigitsDD.value;
    const dateString = `${mm}-${dd}-${this.fourDigitsYYYY.value}`;

    this.twoDigitsMM.setValue(mm);
    this.twoDigitsDD.setValue(dd);

    console.log(dateString);
    if (!/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
      return false;
    }

    const parts = dateString.split('-');
    const month = parseInt(parts[0], 10);
    const day   = parseInt(parts[1], 10);
    const year  = parseInt(parts[2], 10);
    console.log(parts, month, day, year);
    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month <= 0 || month > 12) {
      return false;
    }

    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
      monthLength[1] = 29;
    }

    // Check the range of the day
    return !(day > 0 && day <= monthLength[month - 1]);

  }

  createCaptcha() {
    const captchaElement = document.getElementById('captcha');
    if (captchaElement) {
      captchaElement.innerHTML = '';
    }
    const charsArray        = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*';
    const lengthOtp         = 6;
    const captcha: string[] = [];
    while (captcha.length < lengthOtp) {
      const index = Math.floor(Math.random() * charsArray.length);
      if (!captcha.includes(charsArray[index])) {
        captcha.push(charsArray[index]);
      }
    }
    const canv  = document.createElement('canvas');
    canv.id     = 'captcha';
    canv.width  = 100;
    canv.height = 50;
    const ctx   = canv.getContext('2d');
    if (ctx) {
      ctx.font = '23px Georgia';
      ctx.strokeText(captcha.join(''), 5, 35);
      this.captchaCode = captcha.join('');
      if (captchaElement) {
        document.getElementById('captcha')?.appendChild(canv); // adds the canvas to the body element
      }
    }
  }

  validateCaptcha(event: any) {
    if (event === 'captchaValue') {
      this.captchaRequire = this.captchaValue === '' || this.captchaValue !== this.captchaCode;
    }
  }

  validateInputs() {
    if (this.readonlyName) {
      this.attuidRequire = this.attuid.length !== 6;
    }
    if (this.readonlyAttuid) {
      this.fnRequire = this.firstname === '';
      this.lnRequire = this.lastname === '';
    }
    this.emailRequire   = this.email === '';
    this.contactRequire = this.contact === '';
    console.log(
      !this.twoDigitsMM.getRawValue() || !this.twoDigitsDD.getRawValue() || !this.fourDigitsYYYY.getRawValue(),
      this.twoDigitsMM.getRawValue(), this.twoDigitsDD.getRawValue(), this.fourDigitsYYYY.getRawValue()
    );
    this.dobRequire         = !this.twoDigitsMM.getRawValue() || !this.twoDigitsDD.getRawValue()
                              || !this.fourDigitsYYYY.getRawValue();
    this.ssnRequire         = this.firstssn === '' || this.midssn === '' || this.lastssn === '';
    this.contactRequire     = this.contact === '';
    this.contactLength      = this.contact.length >= 1 && this.contact.length < 10;
    this.altercontactLength = this.altercontact.length >= 1 && this.altercontact.length < 10;
    this.captchaRequire     = this.captchaValue === '' || this.captchaValue !== this.captchaCode;
    return this.captchaValue === this.captchaCode && ((!this.fnRequire && !this.lnRequire) || !this.attuidRequire)
           && !this.emailRequire
           && !this.contactLength && !this.emailValidation && !this.altercontactLength && !this.contactRequire
           && !this.dobRequire
           && !this.ssnRequire;
  }

  submit() {
    if (this.validateInputs()) {
      const registerObj = {
        'attUid'        : this.attuid,
        'firstName'     : this.firstname,
        'lastName'      : this.lastname,
        'emailAddress'  : this.email,
        'cellPhone'     : this.contact,
        'alternatePhone': this.altercontact === '' ? 0 : this.altercontact,
        'dob'           : `${this.twoDigitsMM.value}-${this.twoDigitsDD.value}-${this.fourDigitsYYYY.value}`,
        'ssn'           : this.firstssn + this.midssn + this.lastssn,
        'countryCode'   : this.countryCode,
        'forgotFlag'    : this.passwordService.forgotFlag ? 'T' : 'F'
      };
      this.message      = '';
      this.responseMSG  = '';
      this.existingUser = false;
      this.userId       = '';
      this.loadSpinner  = true;
      this.registerService.postRegisterInfo(registerObj).subscribe(
        {
          next    : (data: any) => {
            this.confirmPage = true;
            if (data?.returnCode === '0') {
              this.loadSpinner  = false;
              this.errorCounter = 0;
              if (data.action === 'New user reminder') {
                this.message
                  = 'Registration is complete. Use the User Name and Temporary Password sent to'
                    + ' you by text or email. Follow directions to login and reset your password.'
                    + ' Upon clicking Ok you will be asked to reset your password using the User'
                    + ' Name and Temporary Password sent to you by text or email';
              } else {
                this.message
                  = 'Reset is complete. Use the User Name and Temporary Password sent to you by'
                    + ' text or email. Follow directions to login and reset your password. Upon'
                    + ' clicking Ok you will be asked to reset your password using the User Name'
                    + ' and Temporary Password sent to you by text or email';
              }

              this.responseMSG            = 'Success';
              this.existingUser           = false;
              this.existingPasswordChange = data.action;
            } else if (data?.returnCode === '-1') {
              this.loadSpinner  = false;
              this.errorCounter = this.errorCounter + 1;
              this.responseMSG  = 'Error';
              if (data.message === 'User Already existed in System Please Logon to System..') {
                this.existingUser = true;
                this.userId       = data.userId;
              } else {
                this.message
                  = 'Unable to process your request at this time, Please try again. or contact'
                    + ' HROnestop Administration';
                this.message = data.message;
              }
            } else {
              this.loadSpinner = false;
              this.message
                               = 'Unable to process your request at this time, Please try again. or contact'
                                 + ' HROnestop Administration';
            }
          },
          error   : (error: any) => {
            this.loadSpinner = false;
            console.log(error);
            this.message
              = 'Unable to process your request at this time, Please try again. or contact HROnestop'
                + ' Administration';
          },
          complete: () => {
            console.log('registration submit complete');
          }
        }
      );
    }
  }

  validateEmail(event: any) {
    const emailStr = event.target.value;
    const emailPat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (emailStr === '') {
      return true;
    }
    const matchArray = emailStr.match(emailPat);
    if (matchArray === null) {
      this.emailValidation = true;
      return false;
    } else {
      this.emailValidation = false;
      return true;
    }
  }

  cancel() {
    this.router.navigate(['/']).then(r => console.log(`routed to '/' => `, r));
  }

  navigateToPage() {
    if (this.responseMSG === 'Success') {
      if (this.existingPasswordChange === 'New user reminder') {
        this.router.navigate(['/resetpwd/formeremp']).then(
          r => console.log(`routed to /resetpwd/formeremp =>`, r));
      } else {
        this.router.navigate(['/resetpwd/formerempold']).then(
          r => console.log(`routed to /resetpwd/formerempold =>`, r));
      }
    } else if (this.existingUser) {
      this.router.navigate(['/']).then(r => console.log(`routed to '/' => `, r));
    } else {
      this.confirmPage = false;
      this.validateCaptcha('captchaValue');
      this.textCounter('attuid');
      this.textCounter('firstname');
      this.validateInputs();
      setTimeout(() => {
        console.log('hide');
        this.createCaptcha();
      }, 2000);
    }
  }

  private requireCheck() {
    if (this.attuid.length !== 6 && this.readonlyName) {
      this.attuidRequire = true;
    }
    if (this.readonlyAttuid && this.firstname.trim().length === 0 && this.lastname.trim().length === 0) {
      this.fnRequire = true;
      this.lnRequire = true;
    }
  }

  private attuidCheck(event: string) {
    if (event === 'attuid') {
      this.attuidRequire = this.attuid.length !== 6;
      this.fnRequire     = false;
      this.lnRequire     = false;
    }
    if (!this.fnRequire && !this.lnRequire) {
      this.attuidRequire = false;
    }
  }

  private firstnameCheck(event: string) {
    if (event === 'firstname' && this.readonlyAttuid) {
      this.fnRequire = this.firstname.trim().length === 0;
    }
  }

  private lastnameCheck(event: string) {
    if (event === 'lastname' && this.readonlyAttuid) {
      this.lnRequire = this.lastname.trim().length === 0;
    }
  }

  private emailCheck(event: string) {
    if (event === 'email') {
      this.emailRequire = this.email === '';
    }
  }

  private contactCheck(event: string) {
    if (event === 'contact') {
      this.contactRequire = this.contact === '';
      this.contactLength  = this.contact.length >= 1 && this.contact.length < 10;
    }
  }

  private altercontactCheck(event: string) {
    if (event === 'altercontact') {
      this.altercontactLength = this.altercontact.length >= 1 && this.altercontact.length < 10;
    }
  }

  private birthdayCheck(event: string) {
    if (event === 'dobMM' || event === 'dobDD' || event === 'dobYYYY') {
      this.dobRequire = this.validateDate();
    }
  }

  private ssnCheck(event: string) {
    if (event === 'ssn') {
      this.ssnRequire     = this.firstssn === '' || this.midssn === '' || this.lastssn === '';
      this.firstSsnLength = this.firstssn.length < 3;
      this.midSsnLength   = this.midssn.length < 2;
      this.lastSsnLength  = this.lastssn.length < 4;
    }
  }
}

