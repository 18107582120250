<div *ngIf="loadSpinner" class="spinner">
  <mat-spinner></mat-spinner>
</div>
<ng-container *ngIf="!nextPage">
  <div class="forgot-pwd">
    <div class="forgotpwd-form">
      <form>
        <h2>Forgot Password</h2>
        <p class="hint-text">Please provide the information below to generate your new password</p>
        <div class="form-group row required">
          <div class="col-md-4" fxLayout="row" fxLayout.xs="column">
            <div class="child-1">
              <label class="row-md-6" for="username" id="username">User Name:</label>
            </div>
            <div class="child-2">
              <a (click)="navigateToReg()" [routerLinkActive]="'active'" [routerLink]="['/register']"
                 style="font-size: 12px;">Forgot User
                                           Name </a>
            </div>
          </div>
          <div class="col-md-6">
            <input (blur)="validationCheck('username')" [(ngModel)]="username" class="form-control" name="username"
                   placeholder="User Name"
                   type="text">
            <span *ngIf="usernameRequire" class="required-field">
              User Name field is mandatory.
            </span>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4" fxLayout="row" fxLayout.xs="column">
            <div class="child-1">
              <label class="row-md-6" for="secretQuestion" id="secretQuestion">Secret Question:
                <img alt="secret question tooltip"
                     class="helpimageCss"
                     matTooltip="Select the question and provide the answer which was established when creating your original password" matTooltipClass="my-custom-tooltip" src="assets/help.png" />
              </label>
            </div>
            <div class="child-2">
              <a (click)="navigateToReg()" [routerLinkActive]="'active'" [routerLink]="['/register']"
                 style="font-size: 12px;">Forgot Secret Question </a>
            </div>
          </div>
          <div class="col-md-6">
            <mat-form-field style="width: 100%;">
              <mat-label>Secret Question:</mat-label>
              <mat-select (blur)="validationCheck('securityQuestion')" [formControl]="securityQuestion">
                <mat-option *ngFor="let Security of securities" matTooltip="{{Security.value}}"
                            value="{{Security.value}}">
                  {{Security.viewValue}}
                </mat-option>
              </mat-select>
              <span *ngIf="questionRequire" class="required-field">Please Select Secret Question.</span>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group row required">
          <div class="col-md-4" fxLayout="row" fxLayout.xs="column">
            <div class="child-1">
              <label class="row-md-6" for="secretAnswer" id="secretAnswer">Secret Answer:</label>
            </div>
            <div class="child-2">
              <a (click)="navigateToReg()" [routerLinkActive]="'active'" [routerLink]="['/register']"
                 style="font-size: 12px;">Forgot Secret Answer</a>
            </div>
          </div>
          <div class="col-md-6">
            <input (blur)="validationCheck('securityAnswer')" [(ngModel)]="securityAnswer" class="form-control" name="securityAnswer"
                   placeholder="Secret Answer" type="text">
            <span *ngIf="answerRequire" class="required-field">
              Secret Answer field is Mandatory.
            </span>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-3" style="margin-left: 33%">
            <button (click)="forgotPassword()"
                    class="button-bottom btn btn-outline-success" type="submit">Submit
            </button>
          </div>
          <div class="col-md-3">
            <button (click)="cancel()" class="button-bottom btn btn-outline-danger"
                    type="submit">Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<div *ngIf="nextPage && message != 'NOEMAIL'" class="nextPage mat-elevation-z4">
  <h3 style="font-family: 'Roboto', sans-serif;">{{responseMSG}}</h3>
  <p style="font-family: 'Roboto', sans-serif;">{{message}}</p>
  <button (click)="navigateToPage()">Ok</button>
  <div *ngIf="errorCounter >= 3" class="service_desk">
    <span>
      <p>
        Are you experiencing issues? Call HR OneStop at 888-722-1STP (888-722-1787) to speak directly to a HR
        OneStop Customer Care Manager. Speak “Former Employee Site”.
        <br>
        Hours of Operation: 8:00 am – 5:00 pm CST
      </p>
    </span>
  </div>
</div>

<div *ngIf="nextPage && message == 'NOEMAIL'" class="nextPage mat-elevation-z4">
  <h3 style="font-family: 'Roboto', sans-serif;">{{responseMSG}}</h3>
  <p style="font-family: 'Roboto', sans-serif;">We could not reset your password due to missing email address. Click
    <a
      (click)="navigateToReg()" [routerLinkActive]="'active'" [routerLink]="['/register']">here
    </a> to set up your email address and reset your
                                                password.</p>
  <button (click)="navigateToPage()">Ok</button>
  <div *ngIf="errorCounter >= 3" class="service_desk">
    <span>
      <p>
        Are you experiencing issues? Call HR OneStop at 888-722-1STP (888-722-1787) to speak directly to a HR
        OneStop Customer Care Manager. Speak “Former Employee Site”.
        <br>
        Hours of Operation: 8:00 am – 5:00 pm CST
      </p>
    </span>
  </div>
</div>
