import {Component, OnInit} from '@angular/core';
import {Router}            from "@angular/router";
import {PasswordService}   from "../shared/services/password.service";
import {environment}       from "../../environments/environment";

@Component({
             selector   : 'app-dashboard',
             templateUrl: './dashboard.component.html',
             styleUrl   : './dashboard.component.scss'
           })
export class DashboardComponent implements OnInit {

  constructor(private router: Router, public fpService: PasswordService) { }

  ngOnInit() {
  }

  navigateToReg() {
    this.fpService.forgotFlag = true
  }

  navigateToForRegister() {
    this.router.navigate(['/register']).then(r => console.log('navigated to /register', r));
  }

  navigateToFormerEmp() {
    window.open(environment.former_emp, "_self")
  }

  navigateToActiveEmp() {
    window.open(environment.active_emp, "_self")
  }

}
