import {Component, OnInit} from '@angular/core';
import {Router}            from "@angular/router";
import {NewUserService}    from "../shared/services/new-user.service";
import {MatDialog}         from "@angular/material/dialog";
import {DialogData}        from "../shared/interface/dialog-data";
import {MessageComponent}  from "../_dialogs/message/message.component";
import {FaqComponent}      from "../_dialogs/faq/faq.component";

@Component({
             selector   : 'app-new-user',
             templateUrl: './new-user.component.html',
             styleUrl   : './new-user.component.scss'
           })
export class NewUserComponent implements OnInit {


  readonly captchaLength         = 6;
  public dialogData: DialogData  = {header: "", link: "", message: ""};
  public attuid: string          = '';
  public captchaCode: string     = '';
  public captchaValue: string    = '';
  public attuidRequire: boolean  = false;
  public captchaRequire: boolean = false;
  public loadSpinner: boolean    = false;

  constructor(private router: Router, private newUserService: NewUserService, public messageDialog: MatDialog,
              public helpDialog: MatDialog) { }

  ngOnInit(): void {
    this.createCaptcha();
  }

  submit(): void {
    if (this.attuid.length === 6 && !this.captchaRequire) {
      this.loadSpinner = true;
      console.log('submitting value', this.attuid);
      this.newUserService
          .validateAttuid({attuid: this.attuid})
          .subscribe({
                       next    : (data: any) => {
                         console.log(data);
                         this.dialogData  = {header: '', message: '', link: ''}
                         this.loadSpinner = false;
                         if (data?.validAttuid) {
                           console.log(data.validAttuid);
                           this.dialogData.header  = 'ATTUID is registered';
                           this.dialogData.message = 'Thank you! Your user name has been sent to your'
                                                     + ' registered email address';
                         } else {
                           console.log(data.validAttuid);
                           this.dialogData.header = 'ATTUID is not registered';
                           this.dialogData.message
                                                  = `We couldn't find a former employee account for the attuid entered. Please click <a href="${data.link}">here</a> to register.`
                         }
                         this.openResultDialog(this.dialogData);
                       },
                       error   : (error: any) => {
                         this.loadSpinner = false;
                         console.error('find user error', error, error.statusText, error.message);
                         this.openResultDialog({header: error.statusText, message: error.message});
                       },
                       complete: () => {
                         this.loadSpinner = false;
                       }
                     });
    } else {
      alert('Enter correct ATTUID and CAPTCHA Code');
    }

  }


  openResultDialog(dialogData: { header: string, message: string }): void {
    console.log(dialogData);
    const dialogRef = this.messageDialog.open(MessageComponent, {
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      this.cancel();
    });
  }

  openHelpDialog(): void {
    const helpDialogRef = this.helpDialog.open(FaqComponent, {
      disableClose: true,
      data        : {
        header : 'New User? - ATTUID Search and FAQs:',
        message: ''
      }
    });

    console.log({helpDialogRef});

  }


  cancel(): void {
    console.log('cancel clicked');
    this.router.navigate(['/dashboard']).then((r) => console.log('navigation to /dashboard is successful', r));
  }

  createCaptcha() {
    const captchaElement = document.getElementById('createCaptcha');
    if (captchaElement) {
      captchaElement.innerHTML = '';
    }
    const charsArray        = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*';
    const captcha: string[] = [];
    while (captcha.length < this.captchaLength) {
      const index: number = Math.floor(Math.random() * charsArray.length);
      if (!captcha.includes(charsArray[index])) {
        captcha.push(charsArray[index]);
      }
    }
    const canv                                 = document.createElement('canvas');
    canv.id                                    = 'createCaptcha';
    canv.width                                 = 100;
    canv.height                                = 50;
    const ctx: CanvasRenderingContext2D | null = canv.getContext('2d');
    // ctx.font    = '23px Georgia';
    if (ctx) {
      ctx.font = '24px san-serif';
      ctx.strokeText(captcha.join(''), 5, 35);
      this.captchaCode = captcha.join('');
      if (captchaElement) {
        document.getElementById('createCaptcha')?.appendChild(canv); // adds the canvas to the body element
      }
    }
  }

  validateCaptcha(): void {
    this.captchaRequire = this.captchaValue !== this.captchaCode;
  }

}
