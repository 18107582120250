<footer>
  <div class="footer">
        <span class="text">
            AT&T Proprietary (Sensitive Personal Information)
            <br>
            Only for use by authorized individuals in accordance with requirements for specific data categories
            predefined in
            Company security standards for Information Classification and Protection

        </span>
  </div>
</footer>
