import {Injectable}              from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment}             from "../../../environments/environment";

@Injectable({
              providedIn: 'root'
            })
export class NewUserService {

  constructor(private http: HttpClient) { }

  public validateAttuid(data: { attuid: string }) {
    return this.http.post(`${environment.API_URL}/validateAttuid`, data, this.getRequestOptions());
  }

  private getRequestOptions() {
    const header = new HttpHeaders({
                                     'Content-Type': 'application/json'
                                   });
    return {
      headers        : header,
      withCredentials: true
    };
  }

}
