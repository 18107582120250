import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router}    from "@angular/router";
import {PasswordService}           from "../shared/services/password.service";
import {DOCUMENT}                  from "@angular/common";

@Component({
             selector   : 'app-temp-password',
             templateUrl: './temp-password.component.html',
             styleUrl   : './temp-password.component.scss'
           })
export class TempPasswordComponent implements OnInit {

  public username: any        = '';
  public tempPassword: any    = '';
  public nextPage: Boolean    = false;
  public userRequire: Boolean = false;
  public pwdRequire: Boolean  = false;
  public error: any;
  loadSpinner: boolean        = false
  public errorCounter         = 0;
  public capchaCode: any      = '';
  public capchaValue: any     = '';
  capchaRequire: boolean      = false;

  constructor(@Inject(DOCUMENT) private document: any, private router: Router, protected route: ActivatedRoute,
              private tempService: PasswordService) { }

  ngOnInit() {
    setTimeout(() => {
      console.log('hide');
      this.createCaptcha();
    }, 2000);
    console.log(this.route.snapshot.paramMap.get('activeFlag'));
  }

  createCaptcha() {
    this.document.getElementById('captcha').innerHTML = '';
    const charsArray                                  = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*';
    const lengthOtp                                   = 6;
    const captcha                                     = [];
    for (let i = 0; i < lengthOtp; i++) {
      const index = Math.floor(Math.random() * charsArray.length + 1);
      if (captcha.indexOf(charsArray[index]) === -1) {
        captcha.push(charsArray[index]);
      } else { i--; }
    }
    const canv  = document.createElement('canvas');
    canv.id     = 'captcha';
    canv.width  = 100;
    canv.height = 50;
    const ctx   = canv.getContext('2d');
    if (ctx) {
      ctx.font = '23px Georgia';
      ctx.strokeText(captcha.join(''), 5, 35);
      this.capchaCode = captcha.join('');
      this.document.getElementById('captcha')?.appendChild(canv); // adds the canvas to the body element
    }
  }

  validateCaptcha(event: any) {
    if (event === 'capchaValue') {
      (this.capchaValue === '' || this.capchaValue !== this.capchaCode) ? this.capchaRequire = true : this.capchaRequire
        = false;
    }
  }

  validationCheck(event: any) {
    if (event === 'username') {
      this.username === '' ? this.userRequire = true : this.userRequire = false;
    } else if (event === 'tempPassword') {
      this.tempPassword === '' ? this.pwdRequire = true : this.pwdRequire = false;
    }
  }

  resetPassword() {
    this.username === '' ? this.userRequire = true : this.userRequire = false;
    this.tempPassword === '' ? this.pwdRequire = true : this.pwdRequire = false;
    (this.capchaValue === '' || this.capchaValue != this.capchaCode) ? this.capchaRequire = true : this.capchaRequire
      = false;
    if (this.capchaValue === this.capchaCode && !this.userRequire && !this.pwdRequire) {
      const passwordObj = {
        'userId' : this.username,
        'tempPwd': this.tempPassword
      };
      this.loadSpinner  = true
      if (this.route.snapshot.paramMap.get('activeFlag') == 'formeremp') {
        this.tempService.resetPassword(passwordObj).subscribe(
          {
            next : (data: any) => {
              if (data) {
                // this.nextPage = true;
                if (data.returnCode === '0') {
                  this.loadSpinner        = false
                  this.errorCounter       = 0;
                  this.tempService.attuid = this.username;
                  this.router.navigate(
                    [`/changepwd/${this.route.snapshot.paramMap.get('activeFlag')}`],
                    {
                      relativeTo        : this.route,
                      queryParams       : {username: this.username},
                      skipLocationChange: true
                    }
                  ).then(r => console.log('navigated to /changepwd', r));
                } else {
                  this.errorCounter = this.errorCounter + 1
                  this.loadSpinner  = false
                  this.error        = data.message;
                }
              }
            },
            error: (error: any) => {
              this.loadSpinner = false
              console.log(error);
            }
          }
        );
      } else {
        this.tempService.resetPasswordForActive(passwordObj).subscribe(
          {
            next : (data: any) => {
              if (data) {
                // this.nextPage = true;
                if (data.returnCode === '0') {
                  this.loadSpinner = false
                  this.router.navigate(
                    [`/changepwd/${this.route.snapshot.paramMap.get(
                      'activeFlag')}`], {
                      relativeTo        : this.route,
                      queryParams       : {username: this.username},
                      skipLocationChange: true
                    });
                } else {
                  this.loadSpinner = false
                  this.error       = data.message;
                }
              }
            },
            error: (error: any) => {
              this.loadSpinner = false
              console.log(error);
            }
          }
        );
      }

    }
  }

  cancel() {
    this.router.navigate(['/']);
  }

}
