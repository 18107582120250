import {Component, Inject}             from '@angular/core';
import {Router}                        from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogData}                    from "../../shared/interface/dialog-data";

@Component({
             selector   : 'app-message',
             templateUrl: './message.component.html',
             styleUrl   : './message.component.scss'
           })
export class MessageComponent {

  constructor(
    public dialogRef: MatDialogRef<MessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router
  ) {
    console.log('AttuidValidityDialog Component');
  }

  ok(): void {
    this.router.navigate(['/dashboard'])
        .then((r) => {
          console.log("routing to /dashboard is successful", r);
          this.dialogRef.close();
        });
  }

}
