import {Component, OnInit}      from '@angular/core';
import {FormControl}            from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {PasswordService}        from "../shared/services/password.service";
import {environment}            from "../../environments/environment";

@Component({
             selector   : 'app-change-password',
             templateUrl: './change-password.component.html',
             styleUrl   : './change-password.component.scss'
           })
export class ChangePasswordComponent implements OnInit {

  public newPassword: any               = '';
  public confirmPassword: any           = '';
  public email: any                     = '';
  public contact: any                   = '';
  public altercontact: any              = '';
  public nextPage: boolean              = false;
  public npRequire: Boolean             = false;
  public cpRequire: Boolean             = false;
  public securityAnswer: any            = '';
  public securityQuestion               = new FormControl();
  public username: any;
  public questionRequire: Boolean       = false;
  public answerRequire: Boolean         = false;
  public showPasswordCondition: Boolean = false;
  public showSecurityCondition: Boolean = false;
  public passwordCondition: Boolean     = false;
  public loadSpinner: Boolean           = false;
  public securities: Security[]         = [
    {viewValue: 'Model name of my first car?', value: 'Model name of my first car?'},
    {viewValue: 'Favorite pet name?', value: 'Favorite pet name?'},
    {viewValue: 'Favorite movie?', value: 'Favorite movie?'},
    {viewValue: 'Favorite musical group?', value: 'Favorite musical group?'},
    {viewValue: 'Favorite song?', value: 'Favorite song?'},
    {viewValue: 'Favorite restaurant?', value: 'Favorite restaurant?'},
    {viewValue: 'Favorite drink?', value: 'Favorite drink?'},
    {viewValue: 'Name of the best man at your wedding?', value: 'Name of the best man at your wedding?'},
    {viewValue: 'Name of the maid of honor at your wedding?', value: 'Name of the maid of honor at your wedding?'},
    {viewValue: 'Name of your first boyfriend/girlfriend?', value: 'Name of your first boyfriend/girlfriend?'},
    {
      viewValue: 'City of the first live concert that you attended?',
      value    : 'City of the first live concert that you attended?'
    },
    {viewValue: 'AT&T HR Codeword?', value: 'AT&T HR Codeword?'}
  ];
  public emailValidation: boolean       = false;
  emailRequire: boolean                 = false;
  contactLength: boolean                = false;
  altercontactLength: boolean           = false;
  employeeStatus: any;
  homeURL: string                       = '';
  contactRequire: boolean               = false;
  errorCounter                          = 0;
  responseMSG: string                   = '';
  message: any;
  invalidPassword: boolean              = false;
  errorPage: boolean                    = false;
  hide                                  = true;
  answerCondition: boolean              = false;
  hideUserDetails: boolean              = false;
  altercontactRequire: boolean          = false;

  constructor(private router: Router, protected route: ActivatedRoute, private forgotPassword: PasswordService) { }

  ngOnInit() {
    this.homeURL      = environment.HOME_URL;
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams) {
      this.username = queryParams['username'];
    }
    this.getUserDetails();
    // this.nextPage = true;
    // this.loadSpinner = false;
    // this.employeeStatus = '1';
    if (this.route.snapshot.paramMap.get('activeFlag') === 'formerempold') {
      this.hideUserDetails = true;
    }
  }

  validationCheck(event: any) {
    if (event === 'newPassword') {
      this.newPassword === '' ? this.npRequire = true : this.npRequire = false;
    } else if (event === 'securityAnswer') {
      this.securityAnswer === '' ? this.answerRequire = true : this.answerRequire = false;
    } else if (event === 'securityQuestion') {
      this.securityQuestion.value === '' ? this.questionRequire = true : this.questionRequire = false;
    } else if (event === 'email') {
      this.email === '' ? this.emailRequire = true : this.emailRequire = false;
    } else if (event === 'contact') {
      this.contact === '' ? this.contactRequire = true : this.contactRequire = false;
      this.contact.length >= 1 && this.contact.length < 10 ? this.contactLength = true : this.contactLength = false;
    } else if (event === 'altercontact') {
      this.altercontact.length >= 1 && this.altercontact.length < 10 ? this.altercontactLength = true
                                                                     : this.altercontactLength
        = false;
    }
  }

  validatePassword(event: any) {
    if (event === 'confirmPassword') {
      (this.confirmPassword === '' || this.newPassword !== this.confirmPassword) ? this.cpRequire = true
                                                                                 : this.cpRequire = false;
    }
  }

  validateNewPassword() {

    // const passwordRegex = new RegExp(
    //   // tslint:disable-next-line:max-line-length
    //   '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\)+=_\(\]\[~_!@#\$%\^\*])(?=.{8,})|(?=.*[a-z])(?=.*[A-Z])(?=.*[~_!@#\$%\^\*])(?=.{8,})|(?=.*[a-z])(?=.*[0-9])(?=.*[~_!@#\$%\^\*])(?=.{8,})|(?=.*[A-Z])(?=.*[0-9])(?=.*[~_!@#\$%\^\*])(?=.{8,})|(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.{8,})');
    const passwordRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~_!@#$%^*])[A-Za-z\d~_!@#$%^*]{16,}$/);
    this.newPassword.match(passwordRegex);
    if (this.newPassword.match(passwordRegex) === null) {
      this.passwordCondition = true;
    } else {
      const specialChars = '<>@!#$%^&*()_+[]{}?:;|\'"\\,./~`-=';
      const allowedChar  = '!@~#$ %^&*_';
      for (let i = 0; i < specialChars.length; i++) {
        if (this.newPassword.indexOf(specialChars[i]) > -1) {
          console.log(this.newPassword.indexOf(specialChars[i]));
          console.log(this.newPassword[this.newPassword.indexOf(specialChars[i])]);
          const temp = this.newPassword[this.newPassword.indexOf(specialChars[i])];
          console.log(allowedChar.indexOf(temp));
          this.passwordCondition = allowedChar.indexOf(temp) <= -1;

        }
      }

    }
    for (let i = 0; i < this.newPassword.length; i++) {
      if (this.newPassword[i] === this.newPassword[i + 1]) {
        if (this.newPassword[i + 1] === this.newPassword[i + 2]) {
          this.passwordCondition = true;
          return;
        }
      }
    }
    for (const i in this.newPassword) {
      if (+this.newPassword[+i + 1] === +this.newPassword[i] + 1 &&
          +this.newPassword[+i + 2] === +this.newPassword[i] + 2) {
        this.passwordCondition = true;
      }
    }
    const reversePassword: any = this.reverseString(this.newPassword);
    console.log(reversePassword);
    for (const i in reversePassword) {
      if (+reversePassword[+i + 1] === +reversePassword[i] + 1 &&
          +reversePassword[+i + 2] === +reversePassword[i] + 2) {
        this.passwordCondition = true;
      }
    }
    const reversedPassword: any = this.reverseString(this.newPassword);
    console.log(reversedPassword);
    for (const i in reversedPassword) {
      if (String.fromCharCode(reversedPassword.charCodeAt(i) + 1) === reversedPassword[+i + 1] &&
          String.fromCharCode(reversedPassword.charCodeAt(i) + 2) === reversedPassword[+i + 2]) {
        this.passwordCondition = true;
      }
    }
    // Check for sequential alphabetical characters
    for (const i in this.newPassword) {
      if (String.fromCharCode(this.newPassword.charCodeAt(i) + 1) === this.newPassword[+i + 1] &&
          String.fromCharCode(this.newPassword.charCodeAt(i) + 2) === this.newPassword[+i + 2]) {
        this.passwordCondition = true;
      }
    }
    this.passwordCondition = false;

  }

  validateAnswer() {

    // const passwordRegex = new RegExp("^[0-9a-zA-Z !$%@*()_+=`{}\[\];?,.\/]");
    const passwordRegex = new RegExp('^[ A-Za-z0-9/[!#$%*()_@.+-=`{};?,]*$');
    this.securityAnswer.match(passwordRegex);
    this.answerCondition = this.securityAnswer.match(passwordRegex) === null;
    this.answerCondition = false;

  }

  reverseString(str: string) {
    // Step 1. Use the split() method to return a new array
    const splitString = str.split(''); // var splitString = "hello".split("");
    // ["h", "e", "l", "l", "o"]

    // Step 2. Use the reverse() method to reverse the newly created array
    const reverseArray = splitString.reverse(); // var reverseArray = ["h", "e", "l", "l", "o"].reverse();
    // ["o", "l", "l", "e", "h"]

    // Step 3. Use the join() method to join all elements of the array into a string
    // var joinArray = ["o", "l", "l", "e", "h"].join("");
    // "olleh"

    // Step 4. Return the reversed string
    return reverseArray.join(''); // "olleh"
  }

  onPasswordHoverEntry() {
    this.showPasswordCondition = true;
  }

  hidePasswordEye() {
    this.hide = true;
  }

  onPasswordHoverExit() {
    this.showPasswordCondition = false;
  }

  validateEmail(event: any) {
    const emailStr = event.target.value;
    const emailPat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (emailStr === '') {
      return true;
    }
    const matchArray = emailStr.match(emailPat);
    if (matchArray === null) {
      this.emailValidation = true;
      return false;
    } else {
      this.emailValidation = false;
      return true;
    }
  }

  getUserDetails() {
    this.loadSpinner = true;
    const req        = {
      'cspid': this.username
    };

    this.forgotPassword.getTheDetails(req).subscribe(
      {
        next : (data: any) => {
          if (data) {
            this.email        = data.userData.personalEmail;
            // this.contact = data.cellNo;
            this.altercontact = data.userData.homePhone == 0 ? '' : data.userData.homePhone;
            this.contact      = data.userData.cellNumber === 0 ? '' : data.userData.cellNumber;
            this.loadSpinner  = false;
          }
        },
        error: (error: any) => {
          console.log(error);
        }
      }
    );
  }

  getEmployeeStatus() {
    this.loadSpinner = true;
    const req        = {'userId': this.username};
    this.forgotPassword.getEmployeeStatus(req).subscribe(
      {
        next    : (data: any) => {
          if (data) {
            this.nextPage       = true;
            this.loadSpinner    = false;
            this.employeeStatus = data.userStatus;
          }
        },
        error   : (error: any) => {
          console.log(error);
          this.loadSpinner = false;
        },
        complete: () => {
          console.log('getEmployeeStatus complete');
        }
      }
    )
    ;
  }

  changePassword() {
    this.npRequire       = this.newPassword === '';
    this.emailRequire    = this.email === null || this.email === '';
    this.cpRequire       = this.confirmPassword === '' || this.newPassword !== this.confirmPassword;
    this.answerRequire   = this.securityAnswer === '';
    this.questionRequire = this.securityQuestion.value === null;
    this.contactRequire  = this.contact === '';
    if ((!this.npRequire && !this.cpRequire && !this.contactRequire && !this.emailRequire && !this.passwordCondition
        && !this.emailValidation && !this.answerRequire && !this.altercontactLength && !this.questionRequire)
        && (this.newPassword === this.confirmPassword)) {
      this.altercontact = this.altercontact === '' ? 0 : this.altercontact;
      this.loadSpinner  = true;
      const registerObj = {
        /* 'oldPassword': this.oldPassword, */
        'userId'          : this.username,
        'passwordValue'   : this.newPassword,
        'securityQuestion': this.securityQuestion.value,
        'securityAnswer'  : this.securityAnswer,
        'emailId'         : this.email,
        'cellNo'          : this.contact,
        'homePhone'       : this.altercontact
      };
      this.nextPage     = false;
      this.errorPage    = false;
      if (this.route.snapshot.paramMap.get('activeFlag') === 'formeremp'
          || this.route.snapshot.paramMap.get('activeFlag') === 'formerempold') {
        this.forgotPassword.changePassword(registerObj).subscribe(
          {
            next       : (data: any) => {
              if (data.returnCode === '0') {
                this.errorCounter = 0;
                this.getEmployeeStatus();
                this.loadSpinner = false;
              } else if (data.returnCode === '-1') {
                this.loadSpinner  = false;
                this.nextPage     = true;
                this.errorPage    = true;
                this.errorCounter = this.errorCounter + 1;
                this.responseMSG  = 'Error';
                if (data.errorCode === '100002') {
                  this.invalidPassword = true;
                  this.message         = 'The password did not meet AT&T Policies - please re-enter password - '
                                         + 'Click Ok to go to previous screen - message - 100002';
                } else {
                  this.message = 'Unable to process your request, contact your admin ' + data.message + data.errorCode;
                }
              }
            },
            error      : (error: any) => {
              this.loadSpinner = false;
              console.log(error);
            }, complete: () => {
              console.log('changePassword complete');
            }
          }
        );
      } else {
        this.forgotPassword
            .createExitEmplSecurityProfile(registerObj)
            .subscribe({
                         next : (data: any) => {
                           if (data.returnCode === '0') {
                             this.loadSpinner  = false;
                             this.errorCounter = 0;
                             this.getEmployeeStatus();
                           } else if (data.returnCode === '-1') {
                             this.loadSpinner  = false;
                             this.errorCounter = this.errorCounter
                                                 + 1;
                             this.responseMSG  = 'Error';
                             this.nextPage     = true;
                             this.errorPage    = true;
                             if (data.errorCode === '100002') {
                               this.invalidPassword = true;
                               this.message
                                 // tslint:disable-next-line:max-line-length
                                                    = 'The password did not meet AT&T Policies - please re-enter password - Click Ok to go to previous screen - 100002';
                             } else {
                               this.message = 'Unable to process your request, contact your admin '
                                              + data.message
                                              + data.errorCode;
                             }
                           }
                         },
                         error: (error: any) => {
                           this.loadSpinner = false;
                           console.log(error);
                         }
                       });
      }
    } else {
      this.loadSpinner = false;
    }
  }

  cancel() {
    this.router.navigate(['/']).then(r => console.log('navigated to /', r));
  }

  navigateToPage() {
    this.nextPage        = false;
    this.newPassword     = '';
    this.confirmPassword = '';
  }
}

export interface Security {
  viewValue: string;
  value: string;
}
