import {Injectable}                                           from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable}                                           from 'rxjs';
import {environment}                                          from "../../environments/environment";

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (environment.env_name === 'local') {
      const authReq = request.clone(
        {
          setHeaders: {
            'iv-user'    : 'aa083h',
            'csp-cenetid': '158718666'
          }
        }
      );
      return next.handle(authReq);
    }
    return next.handle(request);
  }
}
