import {Component} from '@angular/core';

@Component({
             selector   : 'app-footer',
             templateUrl: './footer.component.html',
             styleUrl   : './footer.component.scss'
           })
export class FooterComponent {

  public fullYear = new Date().getFullYear();

  constructor() { }

}
