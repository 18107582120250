<div class="w-100">
  <h1 class="w-100 text-center" mat-dialog-title>{{data.header}}</h1>
  <div mat-dialog-content>
    <ol>
      <li>
        All employee(s) register after leaving the company. Some employees may have been given
        their Former Employee ID credentials before they left via email. If you have credentials but lost
        them click, <a [routerLink]="['/newUser']">Find Username</a>
      </li>
      <li>
        Forgot my password? Please click on <a [routerLink]="['/forgotpwd']">Forgot Password</a>. A temporary
        password will be emailed to you.
      </li>
      <li>
        Can I use my active credentials? No, as a Former Employee of AT&T you are assigned new credentials.
      </li>
      <li>
        Can I make my own username? Your username was systematically created for you and cannot be changed.
      </li>
    </ol>
  </div>
  <div class="text-center w-25 mx-auto" mat-dialog-actions>
    <button (click)="close()" class="button-bottom btn btn-outline-success w-25 mx-auto" type="submit">Ok</button>
  </div>
</div>
